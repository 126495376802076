import * as _log from './log';
import * as _api from './api';
import * as _transaction from './transaction';
import * as _paymentAdyen from './payment/adyen';
import * as _state from './state';

import * as _notification from './notification';

let _payment = false;

// set the logger
const logger = _log.get('PAYMENT');

const setPaymenttype = (paymenttype) => {
    _payment.paymenttype = paymenttype;
};

const init = async (amount, paymenttype) => {
    _payment = {
        applepay_id: _payment ? _payment.applepay_id : null,
        applepay_merchant_reference: _payment ? _payment.applepay_merchant_reference : null,
        googlepay_id: _payment ? _payment.googlepay_id : null,
        googlepay_merchant_reference: _payment ? _payment.googlepay_merchant_reference : null,
        id: null,
        session_id: null,
        transaction_id: null,
        merchant_reference: null,
        amount: amount,
        paymenttype: {
            id: paymenttype.id,
            name: paymenttype.attributes.name,
            psp_client_key: paymenttype.attributes.psp_client_key,
            psp_environment: paymenttype.attributes.psp_environment,
            psp_method: paymenttype.attributes.psp_method,
            psp_type: paymenttype.attributes.psp_type,
            psp_merchant_account: paymenttype.attributes.psp_merchant_account,
            fee: paymenttype.attributes.fee,
        },
        data: false,
    };

    if (_payment.paymenttype.psp_type == 'adyen_ecommerce') {
        await _paymentAdyen.init(_payment);
    }

    logger.log('initilized payment')();
};

const reinit = async (payment) => {
    _payment = {
        applepay_id: _payment ? _payment.applepay_id : null,
        applepay_merchant_reference: _payment ? _payment.applepay_merchant_reference : null,
        googlepay_id: _payment ? _payment.googlepay_id : null,
        googlepay_merchant_reference: _payment ? _payment.googlepay_merchant_reference : null,
        id: null,
        session_id: null,
        transaction_id: null,
        merchant_reference: null,
        amount: payment.amount,
        paymenttype: payment.paymenttype,
        data: false,
    };

    if (_payment.paymenttype.psp_type == 'adyen_ecommerce') {
        await _paymentAdyen.init(_payment);
    }

    logger.log('reinitilized payment')();
};

const doAdditionalAction = (data, ref) => {
    _paymentAdyen.createFromAction(_payment, data, ref);
};

const setId = (id) => {
    _payment.id = id;
};

const isValid = () => {
    if (
        _payment.paymenttype &&
        _payment.paymenttype.psp_type === 'adyen_ecommerce' &&
        _payment.paymenttype.psp_method !== 'applepay' &&
        _payment.paymenttype.psp_method !== 'googlepay'
    ) {
        return _paymentAdyen.isValid();
    }
    return true;
};

const get = () => {
    return _payment;
};

const handleResultCode = (location_slug, data) => {
    _payment.transaction_id = data.transaction_id;
    _payment.payment_id = data.payment_id;
    _payment.merchant_reference = data.merchant_reference;

    logger.log('result_code: ' + data.action.result_code)();

    // _state.set('transaction/setId', data.transaction_id);
    // _state.set('transaction/setPaymentId', data.payment_id);

    if (data.action.result_code == 'confirmed') {
        window.checkout.redirectToRoute('paymentstatus', {
            payment_id: data.payment_id,
        });
    }

    // failed
    else if (data.action.result_code == 'failed') {
        _notification.set(data.action.message, 'error');
    }

    // canceled
    else if (data.action.result_code == 'canceled') {
        _notification.set(data.action.message, 'error');
    }
    // redirect
    else if (data.action.result_code == 'redirect') {
        // window.location = data.action.redirect_url;
        window.checkout.redirectToUrl(data.action.redirect_url);
    }
    // redirect_adyen
    else if (data.action.result_code == 'adyen_handler') {
        if (_payment.paymenttype.psp_method === 'applepay' || _payment.paymenttype.psp_method === 'googlepay') {
            return;
        }
        // return;
        _paymentAdyen.submit();
    }

    // apple pay?
    else if (data.action.result_code == 'pending') {
        // if apple pay
        // await _payment.init(_payment.paymenttype);
        // _paymentAdyen.initApplePay(_payment);
        //  doAdditionalAction(data.action.adyen_response.action, this);
    }
};

const initDonation = async () => {
    // _payment = {
    //     session_id: null,
    //     paymenttype: {
    //         id: paymenttype.id,
    //         name: paymenttype.attributes.name,
    //         psp_client_key: paymenttype.attributes.psp_client_key,
    //         psp_environment: paymenttype.attributes.psp_environment,
    //         psp_method: paymenttype.attributes.psp_method,
    //         psp_type: paymenttype.attributes.psp_type,
    //     },
    //     data: false,
    // };
    await _paymentAdyen.initDonation();

    // if (_payment.paymenttype.psp_type == 'adyen_ecommerce') {
    //     await _paymentAdyen.init(_payment);
    // }

    logger.log('initilized donation')();
};

const send = async (amount) => {
    // _transaction.setTransactionFee(_payment.paymenttype);
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // post the transaction to api
    let response = await _api.post('payments', {
        amount: amount,
        // amount: _payment.amount,
        transaction_id: transaction.id,
        // payment_id: _payment.id,
        // merchant_reference: _payment.merchant_reference,
        payment_id: getPaymentId(_payment),
        merchant_reference: getPaymentMerchantReference(_payment),
        paymenttype_id: _payment.paymenttype.id,
        payment_data: _payment.data,
        create_transaction: false, // todo: needed?
    });

    logger.log('payment send', response)();
    return response;
};
const cancel = async () => {
    // get the transaction
    //   let transaction = _state.get('transaction/getTransaction')

    // post the transaction to api
    let response = await _api.post('payments/cancel', {
        payment_id: getPaymentId(_payment),
    });

    setId(null);

    logger.log('payment cancel', response)();
    return response;
};

const getPaymentId = (payment) => {
    if (payment.paymenttype.psp_method === 'applepay') {
        return _payment.applepay_id;
    } else if (payment.paymenttype.psp_method === 'applepay') {
        return _payment.googlepay_id;
    }
    return _payment.id;
};

const getPaymentMerchantReference = (payment) => {
    if (payment.paymenttype.psp_method === 'applepay') {
        return _payment.applepay_merchant_reference;
    } else if (payment.paymenttype.psp_method === 'applepay') {
        return _payment.googlepay_merchant_reference;
    }
    return _payment.merchant_reference;
};

export { cancel, get, init, isValid, setId, doAdditionalAction, handleResultCode, initDonation, reinit, send, setPaymenttype };
