import { i18n } from '../../main';

import * as _api from '../api';
import * as _log from '../log';
import * as _state from '../state';
import * as _payment from '../payment';
import * as _notification from '../notification';
import * as _transaction from '../transaction';

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

let _adyen_checkout = false;
let _checkout = false;

// set the logger
const logger = _log.get('ADYEN');

// set paymenttype
let paymenttype = false;
let paymenttypeApplePay = false;
let paymenttypeGooglePay = false;

let googlepayInPayment = false;
let submitAfterAuthorisation = false;

// disable the backbutton
window.onpopstate = (event) => {
    if (googlepayInPayment == true) {
        window.history.go(1);
    }
};

// todo: handle errors
const init = async (payment) => {
    submitAfterAuthorisation = false;

    window.checkout.saving = false;
    logger.log('initilized payment')();

    // set paymenttype
    paymenttype = payment.paymenttype;

    // post to retrieve the paymentmethods
    const responsePaymentmethods = await _api.post('adyen/payments/methods', {
        paymenttype_id: paymenttype.id,
        type: 'adyen_ecommerce',
    });

    if (responsePaymentmethods.result === 'failed') {
        _notification.set('notification.payment.loading.failed', 'error');
        return;
    }

    const paymentMethods = responsePaymentmethods.data;

    let amountFee = 0;
    let amount = payment.amount;
    if (paymenttype.fee && paymenttype.fee.percentage > 0 && (paymenttype.psp_method === 'applepay' || paymenttype.psp_method === 'googlepay')) {
        const transactionAmount = _transaction.getTotalWithoutFees();
        const transactionFee = _transaction.getTransactionFee();

        amount = transactionAmount;
        amountFee = parseFloat(transactionAmount * (paymenttype.fee.percentage / 100).toFixed(2));
    }
    // console.log('email', _transaction.getCustomerEmail());
    // open paymentsession
    const responsePaymentSession = await _api.post('adyen/payments/session', {
        amount: amount + amountFee,
        paymenttype_id: paymenttype.id,
        email: window.checkout.form.customer_email || '',
    });

    if (responsePaymentSession.result === 'failed') {
        _notification.set('notification.payment.session.failed', 'error');
        return;
    }

    payment.id = responsePaymentSession.data.payment_id;
    // payment.applepay_id = responsePaymentSession.data.payment_id;

    // TODO: refacor id to paymentmethod?
    if (paymenttype.psp_method === 'applepay') {
        paymenttypeApplePay = paymenttype;
        payment.applepay_id = responsePaymentSession.data.payment_id;
        payment.applepay_merchant_reference = responsePaymentSession.data.merchant_reference;
    } else if (paymenttype.psp_method === 'googlepay') {
        paymenttypeGooglePay = paymenttype;
        payment.googlepay_id = responsePaymentSession.data.payment_id;
        payment.googlepay_merchant_reference = responsePaymentSession.data.merchant_reference;
    }

    payment.merchant_reference = responsePaymentSession.data.merchant_reference;

    const paymentSession = responsePaymentSession.data.adyen.original;

    const localization = _state.get('config/getConfig')['localization'] || 'en';

    // create config
    const configuration = {
        paymentMethodsResponse: filterUnimplemented(paymentMethods),
        clientKey: paymenttype.psp_client_key,
        locale: localization,
        environment: paymenttype.psp_environment == 'production' ? 'live' : 'test',
        session: paymentSession,
        showPayButton: showPayButton(paymenttype.psp_method),
        onChange: (state) => {
            payment.data = state.data;
        },
        onAdditionalDetails: async (state) => {
            // post to retrieve the paymentmethods
            if (state.data) {
                const response = await _api.post('payments/:payment_id/status', { payment_data: state.data.details }, payment.id);
                if (response.status === 'paid') {
                    window.checkout.redirectToRoute(
                        'paymentstatus',
                        {
                            payment_id: payment.id,
                        },
                        {
                            status: 'confirmed',
                        }
                    );
                } else if (response.status === 'canceled') {
                    window.checkout.endSubmit();
                    // todo: translate
                    _notification.set(i18n.global.t('notification.payment.adyen.canceled'), 'error');
                    // reinit paymentmethod
                    init(payment);
                } else if (response.status === 'Pending') {
                    console.log('pending');
                } else {
                    window.checkout.endSubmit();
                    // todo: translate
                    _notification.set(i18n.global.t('notification.payment.adyen.refused'), 'error');
                    // reinit paymentmethod
                    init(payment);
                }
            }
        },
        onPaymentCompleted: async (result) => {
            googlepayInPayment = false;
            if (result.resultCode === 'Authorised') {
                if (submitAfterAuthorisation === true) {
                    try {
                        await window.checkout.onSubmit({ paymenttype_valid: true });
                        window.checkout.endSubmit();
                    } catch (error) {
                        // modal??
                        _notification.set(i18n.global.t('notification.payment.adyen.applepay-error'), 'error');
                        init(payment);
                        return;
                    }
                }
                window.checkout.redirectToRoute(
                    'paymentstatus',
                    {
                        payment_id: payment.id,
                    },
                    {
                        status: 'confirmed',
                    }
                );
            } else if (result.resultCode === 'Cancelled') {
                _notification.set(i18n.global.t('notification.payment.adyen.canceled'), 'error');

                // reinit paymentmethod
                init(payment);
            } else if (result.resultCode === 'Refused') {
                _notification.set(i18n.global.t('notification.payment.adyen.refused'), 'error');

                // reinit paymentmethod
                init(payment);
            } else if (result.resultCode === 'Error') {
                // todo: translate
                _notification.set(i18n.global.t('notification.payment.adyen.error'), 'error');

                // reinit paymentmethod
                init(payment);
            }
        },
        onError: (error, component) => {
            if (error.message == 'bcmc_mobile App was not found') {
                console.log(error.message);
                return;
            }

            googlepayInPayment = false;
            console.log('onErrror');
            _payment.cancel();

            _payment.reinit(payment);

            _notification.set(i18n.global.t('notification.payment.adyen.aborted'), 'error');
            // init(payment);

            console.error(error.name, error.message, error.stack, component);
        },
    };

    let paymenttypeConfig = null;
    if (paymenttype.psp_method === 'googlepay') {
        paymenttypeConfig = {
            buttonType: 'plain',
            configuration: {
                gateway: 'adyen',
                gatewayMerchantId: paymenttype.psp_merchant_account,
                merchantId: 'BCR2DN4TUSW37PLQ',
            },
            onClick: async (resolve, reject) => {
                window.history.pushState(null, null, window.location.href);

                googlepayInPayment = true;

                _transaction.setTransactionFee(paymenttypeGooglePay);
                _payment.setPaymenttype(paymenttypeGooglePay);

                window.paymenttypes.clearPaymenttype();
                await window.checkout.onSubmit({ paymenttype_valid: true });
                await window.checkout.endSubmit();
                resolve();
            },
        };
    }

    // init adyen checkout
    _adyen_checkout = await AdyenCheckout(configuration);

    if (paymenttype.psp_method === 'applepay' && getBrowserVersion() === 'safari') {
        // apple pay
        const applePayConfiguration = {
            version: 4,
            onClick: async (resolve) => {
                submitAfterAuthorisation = true;
                _transaction.setTransactionFee(paymenttypeApplePay);

                _payment.setPaymenttype(paymenttypeApplePay);

                window.paymenttypes.clearPaymenttype();
                // window.checkout.onSubmit({ paymenttype_valid: true });
                // window.checkout.endSubmit();
                resolve();
            },
        };

        const applePayComponent = _adyen_checkout.create('applepay', applePayConfiguration);

        applePayComponent
            .isAvailable()
            .then(() => {
                _checkout = applePayComponent.mount('#paymenttype_' + paymenttype.psp_method);
            })
            .catch((e) => {
                _notification.set('payment.method.apple-pay.not-available', 'error');
                console.log(e);
                //Apple Pay is not available
            });

        return;
    }
    _checkout = _adyen_checkout.create(paymenttype.psp_method, paymenttypeConfig).mount('#paymenttype_' + paymenttype.psp_method);
};

const submit = () => {
    _checkout.submit();
};

const isValid = () => {
    return _checkout.state.isValid;
};

const createFromAction = (payment, action) => {
    _adyen_checkout.createFromAction(action).mount('#paymenttype_' + payment.paymenttype.psp_method);
};

// filter paymentmethods
function filterUnimplemented(pm) {
    logger.log('filter paymentmethods', pm)();

    pm.paymentMethods = pm.paymentMethods.filter((it) =>
        [
            'scheme',
            'ideal',
            'applepay',
            'bcmc',
            'bcmc_mobile',
            'googlepay',
            'giropay',
            'cartebancaire',
            // 'sepadirectdebit',
            // 'directEbanking',
            // 'ach',
            // 'alipay',
            // 'klarna_paynow',
            // 'klarna',
            // 'klarna_account',
            // 'boletobancario_santander',
        ].includes(it.type)
    );
    return pm;
}

// todo: handle errors
const initDonation = async (payment_id) => {
    logger.log('initilized adyen donation')();

    // get the donation settings
    const settings = _state.get('config/getConfig')['settings']['adyen_giving'];

    if (settings.active === false) {
        return;
    }

    // create config
    const configuration = {
        amounts: {
            currency: 'EUR',
            values: settings.values.split(';').map((value) => value * 100),
        },
        description: settings.description,
        logoUrl: settings.logo_url,
        name: settings.name,
        url: settings.url,
        showCancelButton: false,
        // onDonate: handleOnDonate,
        // onCancel: handleOnCancel,
        onDonate: async (state, component) => {
            if (state.isValid === true) {
                const response = await _api.post('adyen/payments/donation', {
                    payment_id: payment_id,
                    data: state.data,
                    merchant_account: settings.merchant_account,
                });
                if (response.status === 'completed') {
                    component.setStatus('success');
                }
            }
        },
        onCancel: (error, component) => {
            console.error(error.name, error.message, error.stack, component);
        },
        onError: (error, component) => {
            console.error(error.name, error.message, error.stack, component);
        },
    };

    // init adyen checkout
    _adyen_checkout = await AdyenCheckout();

    // const donation = _adyen_checkout.create('donation', configuration).mount('#donation-container');
    _adyen_checkout.create('donation', configuration).mount('#donation-container');
    // mount checkout
    // _checkout = _adyen_checkout.create('ideal').mount('#card-container');
    // _checkout = _adyen_checkout.create(paymenttype.psp_method).mount('#paymenttype_' + paymenttype.psp_method);
};

function getBrowserVersion() {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/chrome|chromium|crios/i)) {
        return 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        return 'firefox';
    } else if (userAgent.match(/safari/i)) {
        return 'safari';
    } else if (userAgent.match(/opr\//i)) {
        return 'opera';
    } else if (userAgent.match(/edg/i)) {
        return 'edge';
    }
    return false;
}

const showPayButton = (paymentmethod) => {
    let browserVersion = getBrowserVersion();
    if (paymentmethod === 'googlepay' || (paymentmethod === 'applepay' && (window.safari !== undefined || browserVersion === 'safari'))) {
        return true;
    }
    return false;
};

export { createFromAction, init, initDonation, isValid, submit };
